import React from "react"
import {RealmAppProvider} from "./src/providers/RealmApp";
import RealmApolloProvider from "./src/providers/RealmApolloProvider";
import "./src/styles/main.css"

export const wrapRootElement = ({element}) =>
  <div className="background">
    <RealmAppProvider appId={process.env.GATSBY_REALM_ID}>
      <RealmApolloProvider>
        {element}
      </RealmApolloProvider>
    </RealmAppProvider>
  </div>