import React, {createContext, useContext, useEffect, useState} from "react";
import * as Realm from "realm-web";
import {Card, Col, Row, Skeleton} from "antd";

const RealmAppContext = createContext();

export const useRealmApp = () => {
  const app = useContext(RealmAppContext);
  if (!app) {
    throw new Error(
      `You must call useRealmApp() inside of a <RealmAppProvider />`
    );
  }
  return app;
};

export const RealmAppProvider = ({appId, children}) => {
  const [app, setApp] = useState(new Realm.App(appId));
  const [currentUser, setCurrentUser] = useState(undefined);
  
  app.logIn(Realm.Credentials.apiKey(process.env.GATSBY_API_KEY)).then(user => {
    setCurrentUser(user)
  })
  
  useEffect(() => {
    setApp(new Realm.App(appId));
  }, [appId]);
  
  const wrapped = {...app, currentUser};
  
  return (
    <RealmAppContext.Provider value={wrapped}>
      {currentUser ? children :
        <Row justify="center" align="middle" style={{height: "100vh"}}>
          <Col xs={23} sm={10}>
            <Card>
              <Skeleton active/>
            </Card>
          </Col>
        </Row>
      }
    
    </RealmAppContext.Provider>
  );
};
