import React, {useEffect, useState} from "react";
import {useRealmApp} from "./RealmApp";
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import fetch from "isomorphic-fetch"

const createRealmApolloClient = (app) => {
  const link = new HttpLink({
    uri: process.env.GATSBY_GRAPHQL_URI,
    fetch: async (uri, options) => {
      if (!app.currentUser) {
        throw new Error(`Must be logged in to use the GraphQL API`);
      }
      await app.currentUser.refreshCustomData();
      options.headers.Authorization = `Bearer ${app.currentUser.accessToken}`;
      return fetch(uri, options);
    },
  });
  
  const cache = new InMemoryCache();
  return new ApolloClient({link, cache});
};

export default function RealmApolloProvider({children}) {
  const app = useRealmApp();
  const [client, setClient] = useState(createRealmApolloClient(app));
  useEffect(() => {
    setClient(createRealmApolloClient(app));
  }, [app]);
  
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
